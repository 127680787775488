import Head from 'next/head';
import { useRouter } from 'next/router';
import { useConfig } from '../../config/queries';

export const SEOFields = ({
  host,
  title,
  indexing,
  description,
}: {
  host: string;
  title?: string;
  indexing?: string;
  description?: string;
}) => {
  const router = useRouter();
  const baseCanonical = router.asPath.split('?')[0];
  const { data } = useConfig();
  const origin =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : '';
  return (
    <>
      <Head>
        <meta content={indexing || 'index, follow'} name="robots" />
        <link rel="icon" href="/images/favicon.ico" />
        <link rel="icon" type="image/svg+xml" href="/images/favicon.svg" />
        <link rel="icon" type="image/png" href="/images/favicon.png" />
        <title>{title || 'Stake&Chips'}</title>
        <meta name="description" content={description} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        ></meta>
        {/* <meta name="viewport" content="width=device-width" /> */}
        <link rel="canonical" href={`${origin}${baseCanonical}`}></link>
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`${origin}${router.asPath}`}
        ></link>
        {data?.languages.map((lang) => (
          <link
            key={lang}
            rel="alternate"
            hrefLang={lang}
            href={`${origin}/${lang}${router.asPath}`}
          ></link>
        ))}
      </Head>
    </>
  );
};
