import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Spinner } from './Spinner';

export const WithLoading = ({ isLoading, children }) => {
  const [_isLoading, changeIsLoading] = useState(isLoading);
  useEffect(() => {
    if (isLoading) {
      changeIsLoading(isLoading);
    } else {
      setTimeout(() => changeIsLoading(isLoading), 500);
    }
  }, [isLoading]);
  return _isLoading ? <Spinner /> : <>{children}</>;
};
